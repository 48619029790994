.projects {
    padding: 80px 20px;
    background: linear-gradient(to bottom, #1b1f26, #23272f);
    color: white;
    text-align: center;
}

.projects-title {
    font-size: 2.5em;
    margin-bottom: 40px;
    color: var(--primary);
}

.project-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    justify-content: center;
    align-items: start;
}

.project-card {
    background-color: #2a2e38;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 100%;
}

.project-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.5);
}

.project-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-bottom: 3px solid var(--primary);
}

.project-content {
    padding: 20px;
    width: 100%;
}

.project-content h3 {
    font-size: 1.4em;
    margin-bottom: 10px;
    color: white;
}

.project-description {
    font-size: 1em;
    line-height: 1.5;
    color: #eaeaea;
    margin-bottom: 20px;
}

.btn.primary {
    display: inline-block;
    padding: 8px 20px;
    border-radius: 20px;
    font-size: 1em;
    font-weight: bold;
    color: white;
    background-color: var(--primary);
    text-decoration: none;
    transition: all 0.3s ease;
    box-shadow: 0 8px 15px rgba(0, 123, 255, 0.3);
}

.btn.primary:hover {
    background-color: var(--primary-hover);
    transform: translateY(-3px);
    box-shadow: 0 15px 25px rgba(0, 123, 255, 0.5);
}
