.cookie-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #1b1f26;
    color: #ffffff;
    padding: 15px 20px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.5);
    z-index: 2000;
    animation: slideIn 0.5s ease;
}

.cookie-banner-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.cookie-banner-content p {
    margin: 0;
    font-size: 14px;
    flex: 1;
}

.cookie-banner-actions {
    display: flex;
    gap: 10px;
}

.accept-button,
.decline-button {
    padding: 8px 16px;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.accept-button {
    background-color: #007bff;
}

.accept-button:hover {
    background-color: #0056b3;
}

.decline-button {
    background-color: #444;
}

.decline-button:hover {
    background-color: #333;
}

.legal-link {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
    font-size: 14px;
    padding: 0;
}

.legal-link:hover {
    color: #0056b3;
}


@keyframes slideIn {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}
