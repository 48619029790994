.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: transparent;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    z-index: 1000;
}

.navbar.scrolled {
    background: #0d1117;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
}

.logo {
    font-size: 1.2em;
    font-weight: bold;
    color: white;
    text-decoration: none;
    white-space: nowrap;
}

.nav-links {
    list-style: none;
    display: flex;
    gap: 20px;
    transition: transform 0.3s ease;
}

.nav-links a {
    text-decoration: none;
    color: white;
    font-weight: bold;
    transition: color 0.3s ease;
}

.nav-links a:hover {
    color: #007bff;
}

.nav-links li a:focus {
    outline: 2px solid #007bff;
    outline-offset: 2px;
}


.hamburger {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 24px;
    height: 18px;
    background: none;
    border: none;
    cursor: pointer;
}

.hamburger-line {
    width: 100%;
    height: 3px;
    background-color: white;
    border-radius: 2px;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.hamburger.open .hamburger-line:nth-child(1) {
    transform: translateY(7px) rotate(45deg);
}

.hamburger.open .hamburger-line:nth-child(2) {
    opacity: 0;
}

.hamburger.open .hamburger-line:nth-child(3) {
    transform: translateY(-7px) rotate(-45deg);
}


.nav-links {
    transition: transform 0.3s ease;
}

.nav-links.open {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    flex-direction: column;
    background-color: rgba(27, 31, 38, 0.95);
    padding: 20px 0;
    transform: translateY(0);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.nav-links li {
    text-align: center;
    margin: 10px 0;
}


.nav-links li {
    animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@media (max-width: 768px) {
    .nav-links {
        display: none;
        transform: translateY(-100%);
    }

    .nav-links.open {
        display: flex;
    }

    .hamburger {
        display: flex;
    }
}

.hamburger:focus,
.hamburger:active {
    outline: 2px solid #007bff;
    outline-offset: 2px;
}

.nav-links a {
    font-size: 1rem;
}
