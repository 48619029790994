.about {
    background: linear-gradient(to bottom, #1b1f26, #23272f);
    padding: 80px 20px; 
    color: white; 
    text-align: center; 
    border-radius: 10px;
}

.about-container {
    max-width: 800px; 
    margin: 0 auto; 
}

.about-title {
    font-size: 2.5em; 
    font-weight: 700;
    color: var(--primary); 
    margin-bottom: 10px; 
}

.about-subtitle {
    font-size: 1.2em;
    color: #a1a1a1; 
    margin-bottom: 20px;
}

.about-image {
    width: 150px; 
    height: 150px;
    border-radius: 50%; 
    object-fit: cover; 
    margin: 20px auto; 
    border: 3px solid var(--primary); 
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); 
}

.about-text {
    font-size: 1.2em;
    line-height: 1.6;
    color: #eaeaea; 
    margin-bottom: 20px; 
}

@media (max-width: 768px) {
    .about {
        padding: 60px 10px;
    }

    .about-title {
        font-size: 2em;
    }

    .about-text {
        font-size: 1.1em;
    }
}
