.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background: #1b1f26;
    padding: 20px;
    border-radius: 12px;
    width: 90%;
    max-width: 600px;
    max-height: 80vh;
    color: white;
    position: relative;
    overflow-y: auto;
    animation: fadeIn 0.3s ease;
}


.modal-content::-webkit-scrollbar {
    width: 8px;
}

.modal-content::-webkit-scrollbar-thumb {
    background-color: #444;
    border-radius: 4px;
}

.modal-content::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    color: white;
    cursor: pointer;
    transition: color 0.3s ease;
}

.modal-close:hover {
    color: #ff5c5c;
}

.legal-links {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 16px;
}

.legal-links button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
    font-size: 14px;
}

.legal-links button:hover {
    color: #0056b3;
}


@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
