.certificates {
    padding: 80px 20px;
    background: linear-gradient(to bottom, #1b1f26, #23272f);
    color: white;
    text-align: center;
}

.certificates-title {
    font-size: 2.5em;
    margin-bottom: 40px;
    color: var(--primary);
}

.certificate-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
    justify-content: center;
}

.certificate-card {
    background-color: #2a2e38;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    text-align: center;
    cursor: pointer;
}

.certificate-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.5);
}

.certificate-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-bottom: 3px solid var(--primary);
}

.certificate-content {
    padding: 20px;
    flex-grow: 1; 
    display: flex;
    flex-direction: column; 
    justify-content: flex-start;
    align-items: center; 
}

.certificate-title {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: white;
}

.certificate-institution {
    font-size: 1.2em;
    color: #a1a1a1;
}

.certificate-date {
    font-size: 1em;
    color: #eaeaea;
}

.btn {
    display: inline-block;
    padding: 10px 20px;
    background-color: var(--primary);
    color: white;
    text-decoration: none;
    font-size: 1em;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin-top: 15px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.btn.primary:hover {
    background-color: var(--primary-hover);
    transform: translateY(-2px);
}

.no-url {
    margin-top: 15px;
    font-size: 0.9em;
    color: #a1a1a1;
    font-style: italic;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #2a2e38;
    color: white;
    border-radius: 15px;
    padding: 20px;
    max-width: 600px;
    width: 90%;
    text-align: center;
    position: relative;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
    animation: fadeIn 0.3s ease-in-out;
}

.modal-image {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
    object-fit: cover;
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 1.5em;
    color: white;
    background: none;
    border: none;
    cursor: pointer;
}

.modal-close:hover {
    color: var(--primary-hover);
}


@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.95);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}
