.contact-form-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #10141e; 
    padding: 20px;
    background: linear-gradient(135deg, #10141e, #1a1f2b);
    background-image: url('https://www.transparenttextures.com/patterns/dark-mosaic.png'); 
    background-blend-mode: multiply;
}

.contact-form {
    width: 100%;
    max-width: 500px;
    background: #1b1f26;
    padding: 30px;
    border-radius: 16px; 
    box-shadow: 0 6px 24px rgba(0, 0, 0, 0.3); 
    text-align: center;
    animation: fadeIn 0.6s ease-in-out; 
}

.contact-form h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px; 
    font-size: 28px;
    color: #ffffff; 
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
}

.form-group {
    margin-bottom: 20px;
    text-align: left;
}

.form-group label {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-bottom: 8px;
    color: #cfcfcf; 
    font-family: 'Poppins', sans-serif; 
    gap: 8px; 
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 12px;
    font-size: 14px;
    border: 1px solid #444; 
    border-radius: 6px;
    background: #2a2e38; 
    color: #ffffff; 
    transition: border-color 0.3s ease, background 0.3s ease;
}


.form-group input:focus,
.form-group textarea:focus {
    border-color: #007bff;
    outline: none;
    background: #333941; 
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.5);
}

.form-group textarea {
    resize: none;
    min-height: 120px;
}

button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px; 
    width: 100%;
    padding: 12px;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    background: linear-gradient(90deg, #007bff, #0056b3);
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: transform 0.2s ease, background-color 0.3s ease;
}


button:hover {
    transform: scale(1.05);
    background: linear-gradient(90deg, #0056b3, #003f87);
}

button:disabled {
    background-color: #444;
    cursor: not-allowed;
    opacity: 0.7;
}

button.loading {
    position: relative;
    pointer-events: none;
}

button.loading::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 18px;
    border: 3px solid #fff;
    border-radius: 50%;
    border-top-color: transparent;
    animation: spin 0.8s linear infinite;
    transform: translate(-50%, -50%);
}

.success-message {
    margin-top: 20px;
    color: #4caf50;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.success-message::before {
    content: '✔';
    font-size: 18px;
}

.error-message {
    margin-top: 20px;
    color: #f44336;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.error-message::before {
    content: '✖';
    font-size: 18px;
}

.checkbox-group {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 15px;
}

.checkbox-group input[type="checkbox"] {
    accent-color: #007bff;
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.checkbox-group label {
    font-size: 14px;
    color: #cfcfcf;
}

.legal-link {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
    font-size: 14px;
    padding: 0;
}

.legal-link:hover {
    color: #0056b3;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

@media (max-width: 768px) {
    .contact-form {
        padding: 20px;
    }

    button {
        font-size: 14px;
        padding: 10px;
    }
}
