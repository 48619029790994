.skills {
    padding: 80px 20px;
    background: linear-gradient(to bottom, #1b1f26, #23272f);
    color: white;
    text-align: center;
}

.skills-title {
    font-size: 2.5em;
    margin-bottom: 40px;
    color: var(--primary);
}

.skills-container {
    max-width: 1000px;
    margin: 0 auto;
}


.skills-category {
    margin-bottom: 40px;
}

.skills-category-title {
    font-size: 1.8em;
    color: var(--primary);
    margin-bottom: 20px;
    text-align: left;
}


.skills-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}


.skill-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    padding: 10px 15px;
    background-color: #2a2e38;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    overflow: hidden;
}

.skill-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.4);
}

.skill-name {
    font-size: 1.2em;
    flex: 1;
    text-align: left;
    color: white;
    display: flex;
    align-items: center;
    gap: 10px;
}

.skill-bar {
    flex: 2;
    height: 12px;
    background-color: #404756;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
}

.skill-progress {
    height: 100%;
    background: linear-gradient(to right, var(--primary), var(--primary-hover));
    border-radius: 5px;
    transition: width 0.4s ease-in-out;
}

.skill-percentage {
    font-size: 1em;
    color: #eaeaea;
    width: 50px;
    text-align: right;
}
