.footer {
    background-color: #1b1f26;
    color: #ffffff;
    padding: 20px 0;
    text-align: center;
    border-top: 2px solid #007bff;
}

.footer-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}


.footer-navigation {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
}

.footer-link {
    color: #007bff;
    text-decoration: none;
    font-size: 14px;
    transition: color 0.3s ease;
}

.footer-link:hover {
    color: #0056b3;
}


.footer-social {
    display: flex;
    gap: 15px;
}

.social-icon {
    color: #ffffff;
    font-size: 20px;
    transition: color 0.3s ease;
}

.social-icon:hover {
    color: #007bff;
}


.footer-legal {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.legal-link {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
    font-size: 14px;
}

.legal-link:hover {
    color: #0056b3;
}


.footer-copyright {
    font-size: 12px;
    color: #cfcfcf;
}


@media (max-width: 768px) {
    .footer-navigation {
        justify-content: center;
    }
    .footer-social {
        justify-content: center;
    }
    .footer-legal {
        justify-content: center;
    }
}
