.hero {
    position: relative;
    color: white;
    text-align: center;
    padding: 250px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5));
}

.hero-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    filter: brightness(1) saturate(1.2);
    transition: opacity 0.5s ease-in-out;
}

.hero-video.hidden {
    opacity: 0;
}

.hero-video.visible {
    opacity: 1;
}

.hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3));
    z-index: 0;
}

.hero-content {
    position: relative;
    z-index: 1;
    max-width: 1200px;
    text-align: center;
    padding: 20px;
    animation: fadeIn 1.2s ease-in-out;
}

.preloading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeOut 0.5s ease-in-out forwards;
    pointer-events: none;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        visibility: hidden;
    }
}

.hero h1 {
    font-size: 4em;
    font-weight: 900;
    margin-bottom: 15px;
    line-height: 1.2;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6);
    animation: slideInDown 1.2s ease;
}

.hero p {
    font-size: 1.6em;
    font-weight: 300;
    margin-bottom: 30px;
    line-height: 1.6;
    color: #f0f0f0;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    animation: fadeIn 1.5s ease-in-out;
}

.hero-buttons {
    display: flex;
    justify-content: center;
    gap: 25px;
    animation: fadeIn 2s ease;
}

.hero-buttons a {
    display: inline-block;
    padding: 15px 30px;
    font-size: 1.2em;
    font-weight: bold;
    color: white;
    background: linear-gradient(90deg, #007bff, #0056b3);
    border-radius: 10px;
    text-decoration: none;
    transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.4);
}

.hero-buttons a:hover {
    background: linear-gradient(90deg, #0056b3, #003f87);
    transform: scale(1.1);
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.6);
}

.hero-icons {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    gap: 30px;
    animation: fadeInUp 2s ease;
}

.hero-icon {
    font-size: 3em;
    color: white;
    transition: transform 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
}

.hero-icon:hover {
    color: #007bff;
    transform: scale(1.25);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.5);
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideInDown {
    from {
        transform: translateY(-50px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fadeInUp {
    from {
        transform: translateY(50px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}
