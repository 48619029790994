:root {
  --bg-dark: #0a0f24;
  --bg-light: #1b2735;
  --text-light: #ffffff;
  --text-accent: #1f80e0;
  --primary: #1f80e0;
  --primary-hover: #1465a6;
  --border-radius: 15px;
  --font-family: 'Roboto', sans-serif;
}

body {
  background-color: var(--bg-dark);
  color: var(--text-light);
  font-family: var(--font-family);
  margin: 0;
  padding: 0;
}

h1, h2, h3 {
  font-weight: bold;
  color: var(--text-light);
}

button {
  font-family: var(--font-family);
}
.error-message {
  color: red;
  font-weight: bold;
  text-align: center;
  margin: 20px 0;
}

.portfolio-data {
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
  max-width: 90%;
  margin: 20px auto;
  font-family: monospace;
  overflow-x: auto;
}
